import React from "react"
import Layout from "../components/layout"
import troy from "../images/troy.png"
import lct from "../images/lct-parts.jpg"
import AYP from "../images/ayp-parts.jpg"
import Generac from "../images/generac-parts.jpg"
import Subaru from "../images/subaru-parts.jpg"
import Murray from "../images/murray-parts.jpg"
import MTD from "../images/mtd-parts.jpg"
import Honda from "../images/honda-parts.jpg"
import HondaE from "../images/honda-engines-parts.jpg"
import Poulan from "../images/poulan-parts.jpg"
import Oregon from "../images/oregon.jpg"
import Craftsman from "../images/craftsman.png"
import DR from "../images/DR.jpeg"
import Cub from "../images/Cub.jpeg"
import Hart from "../images/hart1.png"


const ServicesPage = () => (
  <Layout>
    <div className="service">
      <h1>Our Services</h1>
      <p>
        If you are looking for expert people who can fix your ATV's, construction equipment, and small engines, choose Millsap Small Engine Repair.
        As one of the most trusted repair shops in North Texas, we
        always provide the best services regardless of the scope of the repair
        jobs that we handle. You will be dealing with
        professionals with decades of experience in small engine repair that
        always value satisfaction.
        Please contact us today at (940) 682-4724 or visit our shop now to discuss your
        needs with one of our mechanics.
      </p>
      <hr/>

    <div classeName="listService">
      <p>Contact us today to find our more about our repair services:</p>
      <ul> 
          <li>ATV's</li>
          <li>Garden Tractor's</li>
          <li>Lawn Mower's</li>
          <li>Generators</li>
          <li>Welder's</li>
          <li>Weedeater's</li>
          <li>Chainsaw's</li>
          <li>Construction Equipment</li>
      </ul>
      </div>
      <hr/>
        <div className="warranty">
            <h3>We are a Warranty Dealer for the following Manufacturers:</h3>
            <div className="slider">
                <div className="shadow">
                <img src={DR} alt="DR"/>
                <img src={Craftsman} alt="Craftsman"/>
                <img src={troy} alt="troy-bilt"/>
                <img src={lct} alt="lct"/>
                <img src={AYP} alt="AYP"/>
                <img src={Generac} alt="Generac"/>
                <img src={Subaru} alt="Subaru"/>
                <img src={Murray} alt="Murray"/>
                <img src={MTD} alt="MTD"/>
                <img src={Honda} alt="Honda"/>
                <img src={Poulan} alt="Poulan"/>
                <img src={HondaE} alt="HondaE"/>
                <img src={Oregon} alt="Oregon"/>
                <img src={Cub} alt="Cub"/>
                <img src={Hart} alt="Hart"/>
                </div>
                
            </div>
        </div>
    </div>
  </Layout>
)

export default ServicesPage
